.main-container{
    background-color: rgb(227, 225, 225);
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: fixed;
    overflow-y: auto;

}

.gateway-container{
    height: 530px;
    position: relative;

}


.gateway-container2{
    height: 530px;
    position: relative;
    width: 375px;
    background-color: white;
    border-radius: 5px;
}


.gateway-details{

    height: 40%;
    width: 375px;
    border-radius: 5px;
}

.logo-style{
    width: 60%;


}
.gateway-bottom{
    background-color: rgb(229 231 235);
    height: 60%;

}

.detail-txt{
    font-family: 'Inter';
font-size: 10px;
font-weight: 400;
line-height: 12.1px;
text-align: left;

}

.detail-value{
    font-family: 'Inter';
font-size: 14px;
font-weight: 500;
line-height: 16.94px;
text-align: left;
width: 100%;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
}

.details-border{
    border-bottom: 1px solid rgba(237, 242, 247, 1);
}

.gateway-block{
    background-color: white;
    height: 70%;
    width: 338px;
    position: absolute;
    z-index: 1;
    top: 25%;
    left: 5%;
    border-radius: 10px;
    overflow: auto;
}

.p-method{
    font-family: 'Inter';
font-size: 14px;
font-weight: 500;
line-height: 16.94px;
text-align: left;

}

.s-img{
    width: 100px
}

.s-height{
    height: 50px;
}

.selected-tile{

}

.option-img{
    width: 70%;
}

.type-txt{
    font-family: 'Inter';
font-size: 14px;
font-weight: 500;
line-height: 16.94px;
text-align: left;
}

.type-tile{
    border: 1px solid rgba(226, 232, 240, 1);
    margin: 0 !important;
    border-right: 4px solid white;
    cursor: pointer;
    height: 55px;
}

.type-tile:hover{
    background-color: rgba(227, 248, 238, 1);
    border-right: 4px solid rgba(123, 200, 161, 1);
}


.type-active{
    background-color: rgba(227, 248, 238, 1);
    border-right: 4px solid rgba(123, 200, 161, 1);
}

.card-footer{
    font-family: 'Inter';
font-size: 10px;
font-weight: 400;
line-height: 10px;
letter-spacing: -0.005em;
text-align: center;
color: rgba(160, 174, 192, 1);
}


.success-txt{
    font-family: 'Inter';
font-size: 18px;
font-weight: 600;
line-height: 24px;
text-align: center;
color: rgba(16, 24, 40, 1);
padding-top: 20px;

}

.success-amt{
    color: rgba(174, 58, 214, 1);
    font-family: 'Inter';
font-size: 20px;
font-weight: 700;
line-height: 24.2px;
text-align: center;
padding-top: 20px;
}

.success-desc{
    //styleName: Text sm/Regular;
font-family: 'Inter';
font-size: 14px;
font-weight: 400;
line-height: 20px;
text-align: center;
color: rgba(102, 112, 133, 1);
padding-top: 20px;
}

.link-success{
  color: green;
}

.CardFormFieldGroupIconOverflow {
    display: flex;
    align-items: center;
    height: 16px;
    position: relative;
    width: 28px;
  }
  
  .CardFormFieldGroupIconOverflow-Item {
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity 5.8s ease, transform 5.8s ease;
  }
  
  .CardFormFieldGroupIconOverflow-Item--1 {
    animation: fadeInOut 5.8s linear infinite;
    animation-delay: 0s;
  }
  
  .CardFormFieldGroupIconOverflow-Item--2 {
    animation: fadeInOut 5.8s linear infinite;
    animation-delay: 2.8s;
  }

  .fade-enter {
    opacity: 0;
    transition: opacity 5s ease-in;
  }
  
  .fade-enter-active {
    opacity: 1;
  }
  
  
  @keyframes fadeInOut {
    0%, 100% {
      opacity: 0;
      transform: scale(0.9);
    }
    50% {
      opacity: 1;
      transform: scale(1);
    }
  }



  @keyframes slideIn {
    from {
      transform: translateX(-100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  .slide-in {
    animation: slideIn 0.5s ease-in-out;
  }

  .pay-footer{
    font-family: 'Inter';
font-size: 10px;
font-weight: 400;
line-height: 10px;
letter-spacing: -0.005em;
text-align: center;
color: rgba(160, 174, 192, 1);
text-align: center;
  }

  @media screen and (max-width:728px){
    input{
      font-size: 16px  !important;
  }

  }
  
@media screen and (max-width:400px){

    .gateway-details{
        background-color: rgba(5, 27, 19, 1);
        height: 40%;
        width: 100%;
    }

    .gateway-container{
        height: 100%;
        position: relative;
    }
    
    .gateway-block{
        background-color: white;
        height: 60vh;
        width: 90%;
        position: absolute;
        z-index: 1;
        top: 25%;
        left: 5%;
        border-radius: 10px;
        overflow: auto;
    }

    .main-container{
        background-color: rgb(227, 225, 225);
        height: 100vh;
        display: block;
        align-items: center;
        justify-content: center;
        width: 100%;
        position: fixed;
        overflow-y: auto;
    
    }
}