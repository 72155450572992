.recurring-box{
    background-color: rgba(255, 238, 238, 1);
    padding: 10px;
    border-radius: 3px;
}

.recurring-header{
    font-family: 'Inter';
font-size: 10.9px;
font-weight: 400;
line-height: 13.19px;
text-align: left;
color: rgba(255, 0, 0, 1);
}

.recurring-desc{
    font-family: 'Inter';
font-size: 9.9px;
font-weight: 400;
line-height: 14px;
text-align: left;
color: rgba(74, 85, 104, 1);
}